<template>
  <div class="resumeForm" v-if="show">
    <b-container>
      <b-form @submit="onSubmit" @reset="onReset">
        <!---------------------->
        <b-form-group
          id="input-group-last"
          label="Resume title:"
          label-for="input-title"
          description="Note: This title is for your own reference and it
             won't apear on your resume"
        >
          <b-form-input
            id="input-title"
            v-model="resume.title"
            placeholder="Select a title for your own reference"
            required
          ></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="primary">{{ buttonText }}</b-button>
        <b-button
          class="ml-3"
          @click="$bvModal.hide(modalId)"
          variant="secondary"
          >Cancel</b-button
        >
      </b-form>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "ResumesForm",
  data() {
    return {
      resume: {
        title: "",
      },
      show: true,
    };
  },
  computed: {
    title: function () {
      if (this.atype === "edit") {
        console.log(this.atype);
        return "";
      } else return "Add a new resume";
    },
    buttonText: function () {
      if (this.atype === "edit") {
        return "Continue";
      } else return "Create";
    },
  },
  props: ["atype", "resumeID", "resumeTitle", "modalId"],
  mounted() {
    if (this.atype !== "edit") return;
    this.resume.title = this.resumeTitle;
    this.show = false;
    this.$nextTick(() => {
      this.show = true;
    });
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      let resumeJson = {
        user_id: this.$store.state.user.id,
        title: this.resume.title,
      };

      if (this.atype === "add") {
        this.$emit("createResume", resumeJson);
      } else if (this.atype === "edit") {
        this.$emit("editResume", this.resume.title);
      }
    },
    onReset(event) {
      event.preventDefault();
    },
  },
};
</script>
<style scoped>
.resumeForm {
  text-align: left;
}
</style>
