<template lang="">
  <b-col class="text-center"  xl="4" lg="4"  md="6"  sm="12"  cols="12">
    <b-card
      header-tag="header"
      header-text-variant="white"
      header-bg-variant="secondary"
      border-variant="secondary"
      class="d-grid justify-content-center mb-4 ml-2"
      align-h="around" >
      <template #header >
        <b-button-close text-variant="danger" 
          @click="confirmDelete(resume)" aria-label="Close"/>
        <h5 class="text-truncate display-6" :title="resume.title"> {{resume.title}} </h5>         
      </template>
      <b-row>
        <b-col class="text-center" cols="10">
          <b-img fluid-grow thumbnail blank-color="red"  :alt="resume.title" 
          class="resume_img" src="../assets/resume_placeholder.png" />
        </b-col>
      </b-row>
      <b-card-text>
         <small><b>Last modified:</b></small><br />
         <small><em> 
          {{ resume.last_modified | formatDate }} 
        </em></small>
      </b-card-text>
      <div class="d-grid d-md-flex justify-content-center gap-2 d-md-block">
        <b-button  class="px-3 mb-2" 
        block variant="primary" @click="edit(resume)">
        Edit
        </b-button>
        <b-button  class="mb-2 ml-4"  variant="dark">Duplicate</b-button>
      </div>
    </b-card>
  </b-col>
</template>

<script>
export default {
  props: ["resume", "resumeModal"],
  methods: {
    edit: function (resume) {
      this.$emit("selectResume", resume);
    },
    remove: function (resume) {
      this.$emit("deleteResume", resume);
    },
    confirmDelete(resume) {
        
        this.$bvModal.msgBoxConfirm('Are you sure?', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(value => {
            if(value == true){
              this.remove(resume)
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
  },
};
</script>
<style scoped>
@media (max-width: 768px) {
  .btn-responsive {
    padding:2px 4px;
    font-size:80%;
    line-height: 1;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .btn-responsive {
    padding:4px 9px;
    font-size:90%;
    line-height: 1.2;
  }
}

.btn-link {
  color: rgb(240, 131, 131);
}
.card {
  background: rgb(242, 248, 252);
}
/* .resume_img {
  margin: 10px;
  width: 10rem;
} */
</style>
