<template lang="">
  <div class="mb-5">
    <h3>Select or Add Resume</h3>
    <b-button
      class="mt-3 mb-3"
      @click="$bvModal.show(addModal)"
      variant="primary"
    >
      <b-icon icon="plus-square"></b-icon>
      &nbsp; Create a new resume
    </b-button>
    <b-container>
      <b-row>
        <resume-card
          v-for="resume in sortResumes"
          :key="resume.id"
          :resume="resume"
          :resumeModal="resumeModal"
          @selectResume="selectResume"
          @deleteResume="deleteResume"
        ></resume-card>
      </b-row>
      <b-modal
        :id="resumeModal"
        hide-footer
        centered
        hide-header
        class="resume"
      >
        <div class="d-block text-center">
          <resume-form
            @editResume="editResume"
            modalId="resumeModal"
            :resumeID="selected_resume.id"
            :resumeTitle="selected_resume.title"
            atype="edit"
          />
        </div>
      </b-modal>

      <!-- <resume-form atype="add" /> -->

      <b-modal
        :id="addModal"
        hide-footer
        centered
        size="lg"
        class="resume"
        title="Create a new resume"
      >
        <div class="d-block text-center">
          <resume-form
            @createResume="createResume"
            modalId="addModal"
            hide-footer
            centered
            atype="add"
          />
        </div>
      </b-modal>
    </b-container>
  </div>
</template>
<script>
import ResumesForm from "../components/forms/FormResumes.vue";
import ResumeCard from "../components/ResumeCard.vue";

export default {
  data() {
    return {
      resumes: [],
      resumeModal: "resumeModal",
      addModal: "addModal",
      selected_resume: [],
    };
  },
  components: {
    "resume-form": ResumesForm,
    "resume-card": ResumeCard,
  },
  created() {
    if (this.$auth.isAuthenticated && this.$store.state.user.id > 0) {
      this.$api.fetchResumes();
    }
  },
  computed: {
    sortResumes() {
      let result = this.$store.state.resumes;
      return result.sort(function (a, b) {
        return Date.parse(b.last_modified) - Date.parse(a.last_modified);
      });
    },
  },
  methods: {
    /////////////////////f/////////////////////////////////////////////////////////////////////
    selectResume: function (resume) {
      this.$bvModal.show(this.resumeModal);
      this.$store.commit("init_resume");
      this.selected_resume = this.$store.state.resume;
      this.selected_resume.id = resume.id;
      this.selected_resume.title = resume.title;
      this.$store.commit("updateResume", this.selected_resume);
    },
    //////////////////////////////////////////////////////////////////////////////////////////
    editResume: function (title) {
      this.selected_resume.title = title;
      this.$store.commit("updateResume", this.selected_resume);
      this.$api.updateResumeTitle().then(() => {
        this.$api.fetchResumeContacts();
        this.$api.fetchResumeExperinces();
        this.$api.fetchResumeEducations();
        this.$api.fetchResumeSkills();
        this.$router.push("contactinfo");
      });
    },
    //////////////////////////////////////////////////////////////////////////////////////////
    createResume: function (resumeJson) {
      this.$store.commit("init_resume");
      let resumesList = this.$store.state.resumes;
      resumesList.push(resumeJson);
      this.$store.commit("updateResumesList", resumesList);

      this.$api.createResume(resumeJson);
      let resume = this.$store.state.resume;
      resume.contactInfo.firstname = this.$store.state.user.firstname;
      resume.contactInfo.lastname = this.$store.state.user.lastname;
      resume.contactInfo.email = this.$store.state.user.email;
      resume.contactInfo.phone = this.$store.state.user.phone;
      resume.contactInfo.address = this.$store.state.user.address;
      console.log("==============================");
      console.log(resume);
      this.$store.commit("updateResume", resume);
      this.$router.push("contactInfo");
    },
    //////////////////////////////////////////////////////////////////////////////////////////
    deleteResume: function (resume) {
      this.$api.deleteResume(resume);
    },
  },
};
</script>
<style lang="scss">
.modal-body {
  background: rgb(255, 255, 255);
  backdrop-filter: blur(16px);
}
</style>
